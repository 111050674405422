<script>
import { mapGetters, mapMutations } from 'vuex'
import stepsMixin from '@/mixins/stepsMixin'
export default {
	mixins: [stepsMixin],
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
	},

	data() {
		return {
			query: '',
			showResults: false
		}
	},

	computed: {
		clientFiltered() {
			return this.searchClients(this.query)
		},
		...mapGetters({
			// showSearchClient: 'arceStatus/showSearchClient',
			searchClients: 'arceClients/searchClients',
			arceClient: 'arceStatus/arceClient',
			groupStructure: 'arceClients/groupStructure',
		})
	},

	methods: {
		setClient(client) {
			this.showResults = false
			this.setArceClient({...client})
			this.query = ''
		},
		close() {
			this.unsetArceClient()
			this.hideSearchClient()
			this.hiddenSteps()
		},
		next() {
			this.initArceWizard({
				data: {
					start: true,
					client: {...this.arceClient},
					groupStructure: this.groupStructure(this.arceClient.id)
				},
				steps: [
					{
						sort: 1,
						key: 'start',
						status: 'current'
					},
				]
			})
			this.showArceWizard()
			this.hideSearchClient()
			this.arceRatingsAdd({...this.arceClient})
		},
		...mapMutations({
			setArceClient: 'arceStatus/setArceClient',
			unsetArceClient: 'arceStatus/unsetArceClient',
			hideSearchClient: 'arceStatus/hideSearchClient',
			showArceWizard: 'arceStatus/showArceWizard',
			initArceWizard: 'arceStatus/initArceWizard',
			arceRatingsAdd: 'arceRatings/add',
		})
	},

	mounted() {
		this.hiddenSteps()
		this.showSteps('myTourClientSearch')
	}
}
</script>
<template>
	<div  class="s-wizard">
		<div class="s-wizard__wrapper">
			<button class="s-wizard__close" @click="close">
				<svg-icon name="close" />
			</button>
			<div class="s-wizard__dialog">
				<div class="s-wizard__header border-bottom">
					<h1 class="text-center">Search a customer</h1>
				</div>
				<div class="s-wizard__body p-3">
					<div class="position-relative mb-3">
						<div class="s-input" data-v-step="cs1">
							<input id="query" name="query" v-if="!arceClient" placeholder="Search" type="text" v-model="query" @keyup="showResults = true" autocomplete="off">
							<input v-else type="text" disabled :value="`${arceClient.nombre} ${arceClient.bbvaid}`" autocomplete="off">
							<svg-icon class="mx-2" name="search" />
							<span class="m-0" @click="unsetArceClient" v-if="arceClient">
								<svg-icon class="mx-2 s-input__delete" name="close" />
							</span>
						</div>
						<div v-if="showResults" class="s-header__search-results" @click.stop="">
							<ul>
								<li v-for="(client, index) in clientFiltered" :key="`sr_client_${index}_${client.code}`" @click="setClient(client)">
									<div class="d-flex align-items-center">
										<div class="s-query__icon">
											<img :src="require('@/assets/simulator/account_120.png')" alt="">
										</div>
										<div>
											<h5 class="s-query__title m-0">
												<text-highlight :queries="[query]">{{client.nombre}} {{ client.bbvaid }}</text-highlight>
											</h5>
											<p class="s-query__type m-0">{{client.tipo}}</p>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div  v-show="arceClient" class="text-center">
						<button data-v-step="cs2" @click="next" class="s-btn s-btn--primary py-2" type="submit">Continue</button>
					</div>
				</div>
				<div class="s-wizard__footer p-3 d-flex justify-content-end">
					<button @click="close" class="s-btn s-btn--outline ml-auto d-inline-block">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>